// gatsby-browser.js
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "./src/global.css"

// Highlighting for code blocks
import "prismjs/themes/prism-coy.css"

// FontAwesome
import "@fortawesome/fontawesome-svg-core/styles.css"

// Google Tag Managerの初期化を確認する関数
const initGTM = () => {
  if (typeof window !== "undefined" && !window.dataLayer) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })
    console.log("GTM initialized in gatsby-browser.js")
  }
}

// ページ読み込み時にGTMを初期化
export const onClientEntry = () => {
  initGTM()
}

// ページ遷移時にGTMイベントを発火
export const onRouteUpdate = ({ location }) => {
  // GTMが初期化されていない場合は初期化
  initGTM()

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined

  setTimeout(() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath })
    }
  }, 100)

  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: "gatsby-route-change",
      page: location.pathname,
    })

    // サンクスページ（Thanksページ）でコンバージョンイベントを送信
    if (location.pathname.includes("/thanks")) {
      // GRC_Thanksページ用のイベント
      window.dataLayer.push({
        event: "conversion",
        conversion_type: "form_submission",
      })

      // Google広告_thanksページ表示用のイベント
      window.dataLayer.push({
        event: "gtm.historyChange",
        "gtm.newUrl": location.href,
      })

      console.log(
        "GTM conversion events pushed to dataLayer in gatsby-browser.js",
      )
    }
  } else {
    console.warn("dataLayer not found in gatsby-browser.js")
  }
}
